import { render, staticRenderFns } from "./RewardNoticeModalSection.vue?vue&type=template&id=eb6a1adc&scoped=true"
import script from "./RewardNoticeModalSection.vue?vue&type=script&lang=ts"
export * from "./RewardNoticeModalSection.vue?vue&type=script&lang=ts"
import style0 from "./RewardNoticeModalSection.vue?vue&type=style&index=0&id=eb6a1adc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb6a1adc",
  null
  
)

export default component.exports