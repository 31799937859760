import { render, staticRenderFns } from "./RewardPage.vue?vue&type=template&id=07c5a65c&scoped=true"
import script from "./RewardPage.vue?vue&type=script&lang=ts"
export * from "./RewardPage.vue?vue&type=script&lang=ts"
import style0 from "./RewardPage.vue?vue&type=style&index=0&id=07c5a65c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c5a65c",
  null
  
)

export default component.exports