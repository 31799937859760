import { render, staticRenderFns } from "./AppConfirmationSentPane.vue?vue&type=template&id=15589b22&scoped=true"
import script from "./AppConfirmationSentPane.vue?vue&type=script&lang=ts"
export * from "./AppConfirmationSentPane.vue?vue&type=script&lang=ts"
import style0 from "./AppConfirmationSentPane.vue?vue&type=style&index=0&id=15589b22&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15589b22",
  null
  
)

export default component.exports