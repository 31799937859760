import { render, staticRenderFns } from "./ImgSliderSection.vue?vue&type=template&id=74b7da96&scoped=true"
import script from "./ImgSliderSection.vue?vue&type=script&lang=ts"
export * from "./ImgSliderSection.vue?vue&type=script&lang=ts"
import style0 from "./ImgSliderSection.vue?vue&type=style&index=0&id=74b7da96&prod&lang=scss&scoped=true"
import style1 from "./ImgSliderSection.vue?vue&type=style&index=1&id=74b7da96&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b7da96",
  null
  
)

export default component.exports